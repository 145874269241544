import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="col-start-1 sm:col-start-2 col-span-5 sm_col-span-3 mt-36 mb-4">Whoops, nothing here...</h1>
    <Link to ="/" className="col-start-1 sm:col-start-2 col-span-5 sm_col-span-3 "><h5 className="text-turquoise-default">Back to the fun</h5></Link>
  </Layout>
)

export default NotFoundPage
